import React from 'react';

import { colors } from '../../../styles/atoms/colors';
import Icon from '../../../styles/atoms/icons';

const socialLinks = [
  {
    type: 'facebook',
    icon: <Icon id="FaFacebookSquare" size={22} color={colors.gray[600]} />,
  },
  {
    type: 'twitter',
    icon: <Icon id="FaTwitter" size={22} color={colors.gray[600]} />,
  },
  {
    type: 'linkedin',
    title: 'This is a blog post',
    icon: <Icon id="FaLinkedin" size={22} color={colors.gray[600]} />,
  },
];

export default socialLinks;
