const openSocialLink = url => {
  const left = (window.innerWidth - 570) / 2;
  const top = (window.innerHeight - 570) / 2;
  const params = `menubar=no,toolbar=no,status=no,width=570,height=570,top=${top},left=${left}`;
  window.open(url, 'NewWindow', params);
};

const handleSocialLink = ({ type, link, title }) => {
  const socialUrls = {
    facebook: `https://www.facebook.com/sharer.php?u=https://www.shopmonkey.io${link}`,
    twitter: `https://twitter.com/intent/tweet?url=https://www.shopmonkey.io${link}`,
    linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=https://www.shopmonkey.io${link}&title=${title}`,
  };

  openSocialLink(socialUrls[type]);
};

export default handleSocialLink;
