import React from 'react';

import socialLinks from './utils/socialLinks';
import handleSocialLink from './utils/handleSocialLink';

import { Container, List, Button } from './styles/SocialShare.styled';

const SocialShare = ({ heading, prefix, slug, title }) => (
  <Container className="social-share">
    <p className="social-share-heading">{heading || 'Share'}</p>
    <List className="social-share-list">
      {socialLinks.map(({ type, icon }) => (
        <Button
          type="button"
          key={type}
          onClick={() =>
            handleSocialLink({
              type,
              title,
              link: `/${prefix}/${slug}`,
            })
          }
        >
          {icon}
        </Button>
      ))}
    </List>
  </Container>
);

export default SocialShare;
